pre{
    margin-bottom:0;
}

.headers{
    background: #fcfcfc; 
    padding: 10px 15px; 
    margin-bottom: 20px;
}

.api-call{
    font-size:14px;
}

.api-call .description{
    font-weight: 600; 
    margin-top: 20px; 
    margin-bottom: 10px;
}

.api-call .callurl{
    display: block;
    width: 100%;
    background: #f5f5f5;
    padding: 10px;
    border: solid 1px #ccc;
}

.api-call .call-method.get{
    color: #5cb85c;
}